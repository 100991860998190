.ant-steps-item-description {
    margin: -24px auto 40px;
    padding: 0;
}
.ant-steps-vertical:nth-child(1) .ant-steps-item-description {
    margin: -24px auto 0;
}
/* ant Line Bind & Line Add Friend 區塊置中*/
:where(.css-dev-only-do-not-override-j0nf2s).ant-steps.ant-steps-vertical {
    display: flex;
    flex-direction: column;
    /* width: 54%;
    margin: 20px auto; */
}
#line-bind-section .ant-steps.ant-steps-vertical >.ant-steps-item{
    width: 370px!important;
}
:where(.css-dev-only-do-not-override-j0nf2s).ant-steps.ant-steps-vertical >.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
    position: absolute;
    top: 5px;
    inset-inline-start: 18px;
    width: 1px;
    height: 100%;
    padding: 38px 0 6px;
}
@media screen and (max-width:760px) {
    .ant-steps-item-icon {
        width: 35px!important;
        height: 35px!important;
        margin-inline-end: 12px;
    }
    .ant-steps-item-icon span {
        display: inline-block;
        width: 20px;
        height: 20px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 35px!important;
        color: white!important;
    }
    .ant-steps.ant-steps-vertical >.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
        top: 2px;
    }
    :where(.css-dev-only-do-not-override-j0nf2s).ant-steps.ant-steps-vertical >.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
        position: absolute;
        top: 2px;
        inset-inline-start: 15px;
        width: 1px;
        height: 99%;
        padding: 36px 0 3px;
    }
    .ant-steps.ant-steps-vertical >.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail::after {
        width: 6px;
        height: 98%;
        background-color: hsla(180, 62%, 79%, 0.301);
    }
}
