.KidProLoginTitleBox{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.KidProLoginDscrptBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.KidProLoginTitle{
    font-size: 3rem;
    color: #F26419;
    font-weight: bold;
}
.mailLable{
    font-weight: 600;
    text-align: center;
    color: #555;
}
.RegxpErrorSec{
    width: 100%;
    text-align: center;
}
.RegxpError{
    color: #F26419;
}
.loginbuttosec{ 
    text-align: center;
}
.nextStepButton {
    background-color: #1E8C96;
    border: none;
    width: 95%;
    color: white;
    padding: 0.5em;
    border-radius: 3px;   
}
.nextStepButton:hover {
    box-shadow: 1px 1px 10px -1px #1e8c96;
}
.nextStepButton:disabled {
    background-color: #1e8c9699;
    margin-top: 1rem;
}
.nextStepButton:disabled:hover {
    box-shadow: none;
}
@media screen and (max-width:540px) {
    .KidProLoginTitleBox{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .KidProLoginTitle {
        font-size: 58px;
        text-align: right;
        margin-top: 2px;
    }
    .mailLable, .loginWayDirectSec{
        flex:initial;
    }
}