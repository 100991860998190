/*** Popup Style ***/
/* 按鈕右邊界 */
.loginButton {
  margin-right: 1em;
}
/* 給AutoPopup使用，提示標題樣式 */
.modalTitle {
  width: 100%;
  margin-left: 1.2em;
  text-align: center;
  font-size: 1.2em;
  color: #fa9600;
}
/* Popup 按鈕 - 登入/註冊文字 */
.loginText {
  color: #666;
}
.loginText:active {
  color: #eee;
}
/* Popup 整個內容區塊 */
.modal {
  background-color: white;
  border-radius: 3px;
}

/* Popup Header層 */
.modalHeader {
  /* padding: 8px; */
  border-width: 0;
  border: none !important;
  width: 100%;
  border-radius: 3px;
}
.modalClose {
  position: absolute;
  font-weight: 700;
  color: white;
  font-size: 20px;
  top: -25px;
  cursor: pointer;
}
.PopupBanner {
  border-radius: 3px;
  width: 100%;
  height: 100%;
}
.PopupBanner img {
  border-radius: 3px;
  width: 100%;
  height: 100%;
}
.modalBody {
  background-color: white;
}
/*** End Popup Style ***/

/* 取消自動填入 */
input {
  -webkit-text-security: "disc";
}
.inputinfiled {
  width: 100%;
  border: none;
  border-bottom: 1px;
  outline: none;
  border-bottom: 1px solid #585858;
}
.inputinfiled:disabled {
  background-color: #ddd;
}

.loginbuttosec {
  text-align: center;
}
.loginbutton {
  background-color: #1e8c96;
  border: none;
  width: 95%;
  color: white;
  padding: 0.3em;
  border-radius: 3px;
  font-size: 18px;
}
.loginbutton:disabled {
  background-color: #1e8c96aa;
}
.loginbutton:hover {
  box-shadow: 1px 1px 10px -1px #1e8c96;
}
.loginbutton:disabled:hover {
  box-shadow: none;
}
.form {
  width: 100%;
  /* background-color: #FFF096; */
  background-color: #ffffff;
  padding: 0.5rem;
  margin: auto;
  border-radius: 3px;
}
.KidProLoginTitleBox {
  display: flex;
  justify-content: center;
}
.KidProLoginMarkBox {
  display: inline;
  width: 200px;
}
.KidProLoginMark {
  width: 100%;
  height: 100%;
}
.KidProLoginTitle {
  font-size: 58px;
  color: #1e8c96;
  font-weight: bold;
}
.loginWayDirectSec {
  display: inline-block;
  /* width: 75%;     */
  flex: 5;
  text-align: end;
}
.loginWayDirect,
.loginWayDirect:active,
.loginWayDirect:visited {
  font-size: 1rem;
  font-weight: 600;
  text-align: end;
  color: #fa9600;
  cursor: pointer;
}
.loginWayDirect:hover {
  text-decoration: underline;
  color: #f26419;
}
.directSecCol {
  display: flex;
  width: 60%;
  margin: auto;
}
.directSec {
  width: 10rem;
  display: flex;
  margin: 0 auto;
  justify-content: space-around;
  text-align: center;
}
.padforgotSec a {
  color: #1e8c96;
}
.regSec a,
.regSec span {
  font-size: 16px;
  color: #fa9600;
  cursor: pointer;
  text-decoration: underline;
}
.regSec:hover a,
.regSec:hover span {
  color: #f26419;
  cursor: pointer;
  text-decoration: underline;
}
.mailLable {
  font-weight: bold;
  flex: 5;
}
.pdsLable {
  font-weight: bold;
}
.pdsLableEmail {
  font-weight: bold;
}
.divLineSec {
  width: 100%;
}
.divLine {
  width: 100%;
}
.socialLoginSec {
  width: 100%;
  display: flex;
  justify-content: center;
}
/* Google登入包含iframe之區塊 */
.SocialIconGoogle + div {
  /* margin-top: 8px; */
  margin-right: 0.5rem;
  width: 55px !important;
  height: 80px !important;
  position: relative !important;
  left: -20% !important;
  z-index: 10 !important;
  opacity: 0 !important;
}
/* 把Google登入iframe，覆蓋原圖片 */
.socialLoginSec iframe {
  width: 55px !important;
  height: 80px !important;
  cursor: pointer !important;
  /* position: relative!important;
    left: -100%!important;
    z-index: 10!important;
    opacity: 0!important; */
}
/* 社群登入區塊最前方空白區塊 */
.SocialIconNoimg {
  margin-right: 0.5rem;
  width: 55px !important;
  height: 80px !important;
  opacity: 0;
}
.SocialIconSec {
  display: flex;
}
.socialLoginTitle {
  margin: 1rem 0.5rem;
  color: #585858 !important;
  font-weight: bold;
}
.socialLoginTitle div {
  font-size: 16px;
}
.SocialIcon {
  margin-right: 0.5rem;
  cursor: pointer;
  /* 圖片z-index */
  z-index: 0;
  /* background-color: #FFF096; */
  background-color: #ffffff;
}
.countryCode {
  border: 1px solid #ced4da;
  box-shadow: none;
  /* border-radius: 0.375rem; */
  margin-right: 10px;
  border: none;
  border-bottom: 1px solid #585858 !important;
  outline: none;
}
.countryCode:focus {
  border: none;
  border-bottom: 1px solid #585858 !important;
  outline: none;
}
.countryCode:disabled {
  background-color: #ddd !important;
  color: #666 !important;
}
.PhoneSec {
  display: flex;
}
.RegxpErrorSec {
  width: 100%;
  text-align: center;
}

.RegxpError {
  color: #f26419;
}
.nextStepButton {
  background-color: #1e8c96;
  border: none;
  width: 95%;
  color: white;
  padding: 0.5em;
  border-radius: 3px;
}
.nextStepButton:hover {
  box-shadow: 1px 1px 10px -1px #1e8c96;
}
.nextStepButton:disabled {
  background-color: #1e8c9699;
  margin-top: 1rem;
}
.nextStepButton:disabled:hover {
  box-shadow: none;
}

/* 提示收取驗證信文字 */
.registerEmailTipSec {
  width: 100%;
  text-align: center;
}
.registerEmailTip {
  /* color: #1E8C96; */
  color: #555;
  font-weight: 600;
}
/* 電子郵件、電話與切換登入方式區塊 */
.LabelSwitchDiv {
  display: flex;
}
/****** 手機註冊按鈕 *****/
.form_buttonPhoneDiv {
  width: 100%;
  text-align: center;
}
.form_buttonPhone {
  width: 95%;
  margin-bottom: 1.5rem;
  font-size: 14px;
  background-color: #1e8c96;
  border: none;
  color: white;
  box-shadow: 1px 1px 1px rgb(150 145 145);
  padding: 0.5em;
  border-radius: 3px;
}
.form_reverifyButton {
  width: 95%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 14px;
  background-color: #1e8c96;
  border: none;
  color: white;
  box-shadow: 1px 1px 1px rgb(150 145 145);
  padding: 0.5em;
  border-radius: 3px;
}
.form_buttonPhoneSubmitSMS {
  margin-bottom: 0;
}
.form_buttonPhone:hover {
  box-shadow: 1px 1px 10px -1px #1e8c96;
}
.form_buttonPhone:disabled {
  background-color: #1e8c9699;
}
.form_reverifySec {
  display: block;
}
.form_smsSec {
  flex: 9;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}
.form_verifySMSDiv {
  width: 100%;
}
.form_inputPhoneSec {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}
.form_inputPhone:disabled {
  background-color: #ddd !important;
  color: #666 !important;
}
.form_inputSMS {
  width: 100%;
  border: none;
  border-bottom: 1px solid #585858;
  outline: none;
}
.form_buttonPhoneDiv {
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 540px) {
  .KidProLoginTitleBox {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .KidProLoginTitle {
    font-size: 58px;
    flex: 5;
    text-align: right;
    margin-top: 2px;
  }
  .KidProLoginMarkBox {
    display: inline-block;
    flex: 8;
  }
  .LabelSwitchDiv {
    justify-content: space-between;
  }
  .mailLable,
  .loginWayDirectSec {
    flex: initial;
  }
  .form_buttonPhoneDiv {
    width: 100%;
    text-align: center;
  }
  .form_buttonPhone {
    width: 95%;
    font-size: 14px;
    padding: 0.5em;
  }
  .form_smsSec {
    flex-wrap: wrap;
  }
  .form_buttonPhoneDiv {
    width: 100%;
    text-align: center;
  }
}

/*** Form Style ***/
/* *{
    outline: 1px solid red; 

} */

/* button:hover{
    box-shadow: 1px 1px 10px -1px #1e8c96;
    border-radius: 3px;
} */
div,
span,
p {
  font-size: 14px;
}
.container_fluid {
  padding: 0 !important;
}
.submitPageRow {
  width: 80%;
  margin: auto !important;
}
.formcolor {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.form_div {
  padding: 0 !important;
}
form {
  padding: 2rem 1rem 1rem 1rem;
  position: relative;
  background-color: #55dde0;
}
input {
  height: 28px;
  line-height: 28px !important;
}
.form_lableCardFLEXComputerPadding {
  padding: 20px;
}
.form_lableCard {
  display: flex;
  margin-bottom: 30px;
}
.form_lableCard2 {
  display: flex;
  /* margin-bottom: 20px; */
}
.form_lableCard3 {
  display: flex;
  margin-bottom: 20px;
}
.form_lableCard4 {
  display: flex;
  margin-bottom: 5px;
}
.form_lableSec {
  display: flex;
  flex-direction: row;
}
.form_lableCardPhone {
  display: flex;
  margin-bottom: 1rem;
}
.form_buttonPhoneDiv {
  width: 100%;
  text-align: center;
}
.form_buttonPhone {
  /* margin-left: 2px;
    margin-right: 1.6rem;
    margin-top: 7px; */
  width: 99%;
  margin-bottom: 1rem;
  font-size: 14px;
  background-color: #fa9600;
  border: none;
  color: white;
  box-shadow: 1px 1px 1px rgb(150 145 145);
  padding: 3px 8px;
  border-radius: 3px;
}
.form_buttonPhone:hover {
  box-shadow: 1px 1px 10px -1px #1e8c96;
}
.form_buttonPhone:disabled {
  background-color: #d2a561;
}
.form_lableCardSMS {
  display: flex;
  margin-bottom: 0;
}
.form_smsPadding {
  flex: 5.5;
}
.form_smsSec {
  flex: 9;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}
.form_labelPhone {
  margin-left: 3px;
}
.form_verifySMSDiv {
  width: 100%;
}
.form_inputPhoneSec {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.form_inputPhoneDiv {
  display: flex;
}
.form_inputPhone {
  height: 28px;
  line-height: 28px !important;
  width: 154px;
}
.form_inputPhone:disabled {
  background-color: #ddd;
}
.form_inputSMS {
  /* width: 16.5rem; */
  width: 100%;
  /* letter-spacing: 0.8rem;
    padding-left: 0.8rem; */
}
.form_imgbox {
  height: 15px;
  width: 15px;
  margin-right: 1rem;
}
.form_img {
  width: 15px;
}
.lineSec {
  display: flex;
  flex-direction: column;
}
label {
  /* width: 9.375rem; */
  /* margin-right: 1rem; */
  color: #585858 !important;
  font-size: 1rem;
  font-weight: bold;
  display: inline !important;
}
input {
  border: 1px solid #585858;
}
.form_inputName {
  width: 240px;
  flex: 6;
}
.form_inputName:disabled {
  background-color: #ddd;
}
.form_inputEmail {
  width: 240px;
  flex: 6;
}
.form_inputEmail:disabled {
  background-color: #ddd;
}
.form_lableAlign {
  flex: 3;
  width: 100px;
  line-height: 27px;
}
.form_PhonelableAlign {
  /* margin-right: 3rem;
    margin-bottom: 5px; */
  flex: 3;
  width: 111px;
}
/* .form_smsPadding{
    margin-right: 6.8rem;
} */
.form_countryCode {
  width: 70px;
  margin-right: 5px;
  margin-bottom: 5px;
  height: 28px;
}
.form_countryCode:disabled {
  background-color: #ddd;
  border-color: #333;
}
.LineEmailHint {
  display: block;
  font-size: 12px;
  color: #585858 !important;
  /* font-weight: bold; */
  margin-top: 0.3rem;
  margin-bottom: 20px;
  margin-left: 1.9rem;
}
.BottomHint span {
  color: #f26419;
}
.banner_div {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0 !important;
}
.banner_img {
  width: 100%;
}
.titileFlex {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 15px !important;
}
.titile {
  color: #1e8c96;
  font-weight: 700;
  /* font-size: 3rem; */
  text-align: center;
}
.steps {
  color: #585858 !important;
  font-weight: 500;
  font-size: 1rem;
  line-height: 120.52%;
  margin: 0 1rem;
}
.completedStep {
  color: #fa9600;
}
.UserUrlHint a,
.UserUrlHint span,
.UserUrlHint p,
.UserUrlHint div {
  font-size: 12px;
}
.UserUrlHint a {
  color: #1400ff;
  text-decoration: none;
}
.UserUrlHint a:hover {
  color: #1400ff88;
  text-decoration: none;
}
.lineLabel {
  width: 100%;
  flex: 3;
  /* margin-right: 16px; */
  line-height: 26px;
}
/* .lineLoginBox{
    border: 2px solid #000;
} */
.lineLoginImg {
  width: 145px;
  border-radius: 3px;
  margin-top: 2px;
}
.lineLoginImg:hover {
  cursor: pointer;
  box-shadow: 1px 1px 10px -1px #1e8c96;
}
.lineFriendImg {
  width: 145px;
  border-radius: 3px;
  margin-top: 2px;
}
.lineFriendImg:hover {
  cursor: pointer;
  box-shadow: 1px 1px 10px -1px #1e8c96;
}
/* .lineIconBox{
    width: 1.5rem;
    height: 25px;
    background-color: #06C755;
    box-shadow: 1px 1px 1px rgba(121, 115, 115, 0.658);
    border-radius: 3px;
    cursor: pointer;
}
.lineIconBox:hover{
    background-color: #02A52F  ;
    height: 25px;
}
.lineIconImg{
    width: 100%;
    cursor: pointer;
} */
.lineFriendIcon {
  width: 1.5rem;
  height: 21px;
}
.lineIconImg {
  width: 100%;
}
.lineFriendSec {
  display: flex;
  flex-direction: row;
}
.lineFriendLabel {
  width: 33%;
  margin-right: 0;
  line-height: 27px;
}
.lineFriendButtonClick {
  width: 100%;
  flex: 6;
  margin-top: 3px;
}
.lineFriendButtonDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  margin-top: 1px;
}
.lineFriendButton {
  width: 7rem;
  background-color: #06c755;
  display: flex;
  border: none;
  box-shadow: 1px 1px 1px rgba(104, 101, 101, 0.658);
  border-radius: 3px;
}
.hand {
  width: 25px;
  margin-right: 5px;
}
/* iframe{
    width: 100%!important;
    height: 23px!important;
} */
.lineFriendButton:hover {
  background-color: #06c755;
  opacity: #000000 10%;
}
.lineFriendText {
  color: white;
  font-weight: bold;
}
.EndingHint {
  /* font-size: .6rem; */
  color: #585858 !important;
  text-align: center;
}
.EndingHint p {
  margin-bottom: 5px;
}
.BottomHint {
  /* font-size: .6rem; */
  color: #585858 !important;
  text-align: center;
}
.form_urlSec {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.form_urlLabel {
  display: flex;
  width: 21%;
  line-height: 27px;
}
.form_inputUrl {
  width: 240px;
  flex: 6;
}
.form_submitSec {
  /* background-color: #FA9600; */
  width: 30rem;
  margin: auto;
  margin-bottom: 1rem;
}
.form_submitButton {
  width: 100%;
  background-color: #fa9600;
  border: none;
  color: white;
  box-shadow: 1px 1px 1px rgb(150, 145, 145);
  padding: 0.5rem;
  border-radius: 3px;
}
.form_submitButton:hover {
  box-shadow: 1px 1px 10px -1px #1e8c96;
}
.UserUrlHint {
  /* font-size: .6rem; */
  text-align: center;
  margin-bottom: 1rem;
  color: #585858 !important;
}
/* 錯誤訊息區塊 */
.RegxpErrorSec {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}
/* 錯誤訊息 */
.RegxpError {
  color: #f26419;
}
/* 錯誤訊息連結 */
.RegxpErrorAnchor {
  color: #0000ff;
  text-decoration: none;
  cursor: pointer;
}
.RegxpErrorAnchor:hover {
  color: #0000ff88;
}
.StepThreeCopy {
  border: 1px solid #1e8c96;
  border-radius: 6px;
  padding: 5px;
  width: 100%;
  background-color: white;
  display: inline-block;
}
.StepThreeCopy p {
  font-family: "Inter";
  font-style: normal;
  color: #585858 !important;
  font-size: 14px;
  margin: 0;
}

.CopySec {
  width: 100%;
  text-align: end;
}
.CopyButton {
  border: none;
  font-family: "Inter";
  font-style: normal;
  padding: 0.6rem 1.2rem;
  border-radius: 3px;
  background: #1e8c96;
  border-radius: 3px;
  text-align: center;
  color: white;
}
.CopyButton:hover {
  box-shadow: 1px 1px 10px -1px #1e8c96;
}
/* 手機改綁定訊息 */
.phoneUnlinkText {
  width: fit-content;
  padding: 6px 8px;
  margin: 8px auto 0;
  background-color: #96eff1;
  border: 1px solid #6cd3d5;
  border-radius: 6px;
}
@media (max-width: 967px) {
  input {
    height: 24px;
    line-height: 24px !important;
  }
  .form_lableSec {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .submitPageRow {
    width: 95%;
    margin: auto !important;
  }
  .form_inputName,
  .form_inputEmail,
  .form_inputUrl {
    width: 100%;
  }
  .form_smsPadding {
    flex: 1.1;
  }
  .lineLabel {
    flex: 4;
  }
  .lineFriendImg {
    width: 145px;
  }
}
/* @media (max-width: 25.875rem){ */
@media (max-width: 768px) {
  form {
    padding: 0;
    margin: 0;
    background-color: #55dee000;
  }
  .form_div {
    padding: 0 !important;
  }
  .titileFlex {
    display: inline-block;
  }
  .formcolor {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .color {
    padding: 2rem 1rem 1rem 1rem;
    border-radius: 0.5rem;
    margin: 1rem 1rem 1rem 1rem;
    background-color: #55dde0;
  }
  label {
    display: block !important;
  }
  input {
    height: 24px;
    line-height: 24px !important;
  }
  .form_lableAlign {
    width: 100%;
  }
  .form_lableSec {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .form_lableCardFLEXComputerPadding {
    padding: 5px;
  }
  .form_inputName,
  .form_inputEmail,
  .form_inputPhone {
    width: 100%;
  }
  .form_countryCode {
    height: 28px;
  }
  .form_buttonPhoneDiv {
    width: 100%;
    text-align: center;
  }
  .form_buttonPhone {
    width: 95%;
    font-size: 14px;
    padding: 3px 8px;
    /* margin-left: 2px;
        margin-right: 0.8rem;
        margin-top: 0px;
        margin-bottom: 5px; */
  }
  .form_smsSec {
    flex-wrap: wrap;
  }
  .form_smsPadding {
    flex: 0.88;
  }
  .lineLabel {
    flex: 8;
  }
  .lineLoginImg {
    width: 45%;
  }
  .lineFriendImg {
    width: 45%;
  }
  /* .lineIconBox{
        width: 1.5rem;
        height: 25px;
        background-color: #06C755;
        box-shadow: 1px 1px 1px rgba(121, 115, 115, 0.658);
        
    }
    .lineIconBox:hover{
        background-color: #06C755  ;
        opacity: #000000 10%;
    } */
  .lineFriendSec {
    display: flex;
  }
  .lineIconImg {
    width: 100%;
  }
  .lineFriendButtonClick {
    width: 100%;
  }
  .lineFRIENDBUTTON {
    border: 1px solid blue !important;
    height: 25px;
  }
  .lineFriendButton {
    width: 7rem;
    background-color: #06c755;
    display: flex;
    border: none;
    box-shadow: 1px 1px 1px rgba(104, 101, 101, 0.658);
  }
  .lineFriendButton:hover {
    background-color: #06c755;
    opacity: #000000 10%;
  }
  .lineFriendIcon {
    width: 1.5rem;
    height: 21px;
  }
  .lineFriendText {
    color: white;
    font-weight: bold;
  }
  .form_submitSec {
    background-color: #fa9600;
    width: 13.563rem;
    margin-bottom: 1rem;
  }
  .form_submitButton {
    width: 100%;
    background-color: #fa9600;
    border: none;
    color: white;
    box-shadow: 1px 1px 1px rgb(150, 145, 145);
    padding: 0.5rem;
    border-radius: 3px;
  }
  .EndingHint {
    /* font-size: .6rem; */
    color: #585858 !important;
  }
  .UserUrlHint {
    font-size: 1rem;
    margin: 0 1rem 1rem 1rem;
    color: #585858 !important;
  }
  /* .UserUrlHintSpan{
         padding-left: 1rem;
    } */
  .UserUrlHintOne {
    display: block;
  }
  .BottomHint {
    text-align: center;
    width: 100%;
    font-size: 14px;
    color: #585858 !important;
  }
  .buttoncolor {
    background-color: #d9d9d9;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form_inputUrl {
    width: 100%;
  }
  .loginWayDirect {
    text-decoration: underline;
  }
}
/*** END Form Style ***/
