/* 導覽列加入登入按鈕排版 */
.navbarSec {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #666666;
    border-width: 0 0 1px;
}