.babyFormContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: .75rem 1.5rem;
    border: 1px solid #AAA;
    border-radius: 5px;
}
.labelText{
    font-weight: 600!important; /* 無效 */
}
.kidsRowDivSec{
    width: 100%;
    border-radius: 5px;
}
.kidsRowDiv{
    width: 100%;
    margin: .25rem 0 1rem!important;
    padding: 10px 15px;
    background-color: #dadada;
    border-radius: 5px;
}
.kidsRowBTMargin, .kidsRowTMargin, .kidsRowBMargin, .kidsRowB2TMargin, .kidsRowBThMargin, .kidsRowThMargin, .kidsRowBhThMargin, .kidsRowBhTMargin, .kidsRowBT2Margin, .kidsRowT2Margin, .kidsRowB2Margin {
    width: 100%;
    margin: 1rem 0!important;
    padding: 0!important;
    display: flex;
    align-items: center;
}
.kidsRowTMargin{
    margin: 1rem 0 0!important;
}
.kidsRowBMargin{
    margin: 0 0 1rem!important;
}
.kidsRowBThMargin{
    margin: .5rem 0 1rem!important;
}
.kidsRowBhTMargin{
    margin: 1rem 0 .5rem!important;
}
.kidsRowThMargin{
    margin: .5rem 0 0!important;
}
.kidsRowBhThMargin{
    margin: .5rem 0 .5rem!important;
}
.kidsRowB2TMargin{
    width: 100%!important;
    margin: 1rem 0 2rem!important;
}
.kidsRowBT2Margin{
    width: 100%!important;
    margin: 2rem 0 1rem!important;
}
.kidsRowT2Margin{
    width: 100%!important;
    margin: 2rem 0 0!important;
}
.kidsRowB2Margin{
    width: 100%!important;
    margin: 0 0 2rem!important;
}
.kidsBirthdayDiv , .addAndDeleteRowDiv{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    flex-direction: row;
}
.IDcardDiv{
    margin: 10px 0;
    padding: 0!important;
    flex-direction: row;
    align-items: center;
}
.customerDataUpdatedIdcardDiv{
    display: flex;
    flex-direction: column;
}
.IDcardFile {
    margin-left: 1rem;
    height: 3.5rem;
    border-radius: 5px;
    border-color: #58585858;
    background-color: rgba(0, 0, 0, 0.06)!important;
    display: inline-block;
    padding: 10px;
    cursor: pointer;
    width: 78.5%!important;
    /* max-width: 500px; */
}
.form_submitSec{
    /* background-color: #FA9600; */
    width: 100%;
    margin: 1rem auto;
    text-align: center;
 }
 .form_submitSec2{
    /* background-color: #FA9600; */
    width: 80%;
    display: flex;
    flex-direction: row;
    margin: 1rem auto;
    text-align: center;
 }
.form_submitButton{
    font-size: 16px;
    width: 100%;
    max-width: 20rem;
    background-color: #FA9600;
    border: none;
    color:white;
    box-shadow: 1px 1px 1px #b56c00;
    padding: .5rem ;
    border-radius: 3px;
    margin: 10px;
 }
 .form_submitButton:hover{
    box-shadow: 1px 1px 10px -1px #F26419;
 }
 .addRow, .deleteRow {
    /* font-size: 20px;
    width: 12rem!important;
    margin: 5px 50px;
    padding: .25rem ; */
    font-size: 24px;
    width: 5rem!important;
    margin: 5px 50px;
    padding: 0.1rem;
    background-color: #1E8C96!important;
    box-shadow: 1px 1px 1px #0f4e53!important;
}
.addRow:hover, .deleteRow:hover {
    /* background-color: #1E8C96; */
    box-shadow: 1px 1px 10px -1px #259da8!important;
}
/* 刪除寶寶整列資料圖示區塊 */
.deleteKidsDataImgDiv{
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}
/* 刪除寶寶整列資料圖示 */
.deleteKidsDataImg{
    width: 20px;
    cursor: pointer;
}
 /* 使用者資料預覽區塊 */
 .customerDataPreviewDiv{
    width: 100%;
    margin-top: 20px;
    text-align: center;
    margin: 1rem auto;
}
/* 使用者資料預覽訊息 */
.customerDataPreview{
    font-weight: 600;
    color: #0000FF;
    text-decoration: underline;
    cursor: pointer;
}
.customerDataPreview:hover{
    color: #0d6efd;
}

 /* 錯誤訊息區塊 */
.RegxpErrorSec{
    width: 100%;
    margin-top: 20px;
    text-align: center;
    margin: 1rem auto;
}
/* 錯誤訊息 */
.RegxpError{
    color: #F26419;
}
/* 錯誤訊息連結 */
.RegxpErrorAnchor{
    color: #0000FF;
    text-decoration: none;
    cursor: pointer;
}
.RegxpErrorAnchor:hover{
    color: #0000FF88;
}
    @media screen and (max-width:967px) {
        /* .kidsRowB2TMargin{
            width: 84%!important;
        } */
        .IDcardFile {
            width: 72%!important;
        }
        .form_submitSec{
            width: 60%;
            margin: 1rem auto;
         }
         .RegxpErrorSec{
            width: 100%;
            margin: 1rem auto;
        }
    }
        @media screen and (max-width:767px) {
            .babyFormContainer {
                padding: 0 12px;
            }
            .kidsRowBTMargin, .kidsRowTMargin, .kidsRowBMargin, .kidsRowB2TMargin, .kidsRowBThMargin, .kidsRowThMargin, .kidsRowBhThMargin, .kidsRowBhTMargin, .kidsRowBT2Margin, .kidsRowT2Margin, .kidsRowB2Margin{
                width: 100%;
                /* margin: .5rem 0!important; */
                display: flex;
                align-items: initial;
                flex-direction: column;
            }
            .kidsRowBMargin{
                margin: 0 0 .75rem!important;
            }
            /* .kidsRowDiv .kidsRowBMargin{
                flex-direction: row;
            } */
            .IDcardFile {
                width: 100%!important;
                margin-left: 0;
            }
            .addAndDeleteRowDiv{
                flex-direction: row;
            }
            .form_submitSec{
                /* background-color: #FA9600; */
                /* width: 13.563rem; */
                /* width: 90%; */
                margin-bottom: 1rem;
                margin: 1rem auto;
             }
             .form_submitSec2{
                /* background-color: #FA9600; */
                /* width: 13.563rem; */
                width: 90%;
                margin-bottom: 1rem;
                margin: 1rem auto;
             }
            .RegxpErrorSec{
                margin-bottom: 1rem;
                margin: 1rem auto;
            }
            .form_submitButton{
                font-size: 16px;
                width: 100%;
                background-color: #FA9600;
                border: none;
                color:white;
                box-shadow: 1px 1px 1px rgb(150, 145, 145);
                padding: .25rem ;
                border-radius: 3px;
             }
             .addRow, .deleteRow {
                /* font-size: 12px;
                width: 5rem!important; */
                font-size: 18px;
                width: 4rem!important;
                margin: 5px 15px;
                padding: .1rem;
            }
        }