/* *{
    outline: 1px solid red;
} */

.form {
  background-color: white;
  padding: 0 3rem;
}
.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 77px;
  text-align: center;
  color: #f26419;
}
.accountCard {
  margin-bottom: 2rem;
  text-align: center;
}
.subtile {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #585858 !important;
  margin-right: 1rem;
}
.clearAccount {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #fa9600;
  cursor: pointer;
}
.clearAccount:hover {
  color: #f26419;
}
.account {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
}
.passwordCard {
  margin-bottom: 3rem;
}
.password {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #585858 !important;
  margin: 0;
}
.password_input {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}
.password_input:focus {
  box-shadow: 1px 1px 10px -1px #1e8c96;
  outline: 1px solid hsla(216, 98%, 52%, 0.025);
}
.Forgot_passwordSec {
  width: 100%;
  text-align: end;
  display: flex;
  justify-content: flex-end;
}
.Forgot_password {
  display: block;
  margin-top: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #1e8c96;
}
.RegxpErrorSec {
  width: 100%;
  text-align: center;
}
.RegxpError {
  color: #f26419;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}
.login_btnSec {
  text-align: center;
  margin-bottom: 2rem;
}
.login_btn {
  background-color: #1e8c96;
  border: none;
  border-radius: 3px;
  color: #fff;
  padding: 0.5em;
  width: 10rem;
}
.login_btn:hover {
  box-shadow: 1px 1px 10px -1px #1e8c96;
}
.oldCustomer {
  margin: 0;
}
.Return {
  text-align: center;
  margin-bottom: 2rem;
}
.ReturnButton {
  background-color: #1e8c96;
  border: none;
  border-radius: 3px;
  color: #fff;
  padding: 0.5em;
  width: 10rem;
}
.ReturnButton:hover {
  box-shadow: 1px 1px 10px -1px #1e8c96;
}
.confirmSec {
  display: flex;
  justify-content: space-evenly;
}
.confirmTitle p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #585858 !important;
  margin-right: 1rem;
  text-align: center;
}

@media screen and (max-width: 540px) {
  .title {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
  }
  .subtile {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  .clearAccount {
    font-weight: 300;
    font-size: 16px;
    line-height: 120.52%;
  }
  .account {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  .password {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  .Forgot_password {
    font-weight: 300;
    font-size: 16px;
    line-height: 120.52%;
  }
  .RegxpError {
    font-weight: 700;
    font-size: 16px;
    line-height: 120.52%;
  }
  .login_btnSec {
    width: 50%;
    padding: 10px;
  }
  .login_btn {
    padding: 0.5em;
    width: 100%;
    /* height: 38.53px; */
    font-weight: 300;
    font-size: 16px;
    line-height: 120.52%;
  }
  .Return {
    width: 50%;
    padding: 10px;
  }
  .ReturnButton {
    padding: 0.5em;
    width: 100%;
  }
}
