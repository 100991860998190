.card {
  box-sizing: border-box;
  width: 70%;
  height: 100vh;
  margin: auto;
  padding: 10% 0;
}
.backgroundColor {
  background-color: #55dde0;
  height: fit-content;
}
.titleBox {
  box-sizing: border-box;
}
.title {
  color: #fa9600;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.0989583) 0%,
    rgba(255, 255, 255, 0.885417) 11.46%,
    #ffffff 88.02%,
    rgba(255, 255, 255, 0) 100%
  );
  font-size: 40px;
  padding: 1rem;
  text-align: center;
  font-weight: 700;
}
.subtitleSec {
  width: 100%;
  color: #585858 !important;
  box-sizing: border-box;
  padding: 1rem;
}
.subtitle {
  width: 100%;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}
.compleItemSec {
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  margin: 0 auto 2rem auto;
}
.circleSrc {
  width: 15px;
  margin-right: 0.5rem;
}
.circleImg {
  width: 100%;
}
.compleItem {
  display: flex;
  color: #585858 !important;
  font-weight: 700;

  margin-bottom: 1rem;
  width: fit-content;
}
.compleItem span {
  font-size: 1vw;
}
.timmerSec {
  box-sizing: border-box;
  background-color: #fff096;
  border-radius: 12px;
  padding: 0 2rem 1rem 2rem;
  margin-bottom: 1rem;
}
.titleSec {
  display: flex;
  position: relative;
}
.spanSec {
  margin: 8px 28vw 8px;
  font-weight: 700;
  color: #f26419;
  font-size: 1vw;
}
.spanSec span {
  display: inline-block;
  width: 36vw;
  font-size: 1vw;
}
.yogaBallSec {
  position: absolute;
  right: 0;
  top: -5vw;
  right: -6vw;
  width: 9vw;
}
.yogaBallSec img {
  width: 100%;
}
.TeacherIconSec {
  position: absolute;
  top: -3.5rem;
}
.yogaBallSecHeight {
  height: 1vw;
}
.Hint {
  color: #585858 !important;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  margin: 0;
  padding: 1rem;
}

.yogaLink {
  text-decoration: none;
  text-align: center;
  margin: 1rem 0 0 0;
}
.yogaLink a {
  color: #1400ff;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
}

@media (max-width: 540px) {
  .card {
    width: 100%;
    height: 100vh;
  }
  .backgroundColor {
    background-color: #55dde0;
    height: fit-content;
  }
  .title {
    background-color: white;
    padding: 0;
    font-size: 32px;
  }
  .subtitleSec {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1rem 0;
  }
  .subtitle {
    width: 100%;
    font-size: 20px;
  }
  .compleItemSec {
    padding: 0;
    margin-bottom: 3rem;
    font-size: 1rem;
  }
  .compleItem {
    width: 100%;
  }
  .compleItem span {
    font-size: 12px;
  }
  .bold {
    font-size: 0.9rem;
    font-weight: 700;
  }
  .spanSec {
    margin: 8px 20vw 8px;
    font-weight: 700;
    color: #f26419;
    font-size: 1vw;
  }
  .spanSec span {
    display: inline-block;
  }
  .yogaBallSec {
    position: absolute;
    right: -1rem;
    top: -3rem;
    right: -30px;
    width: 90px;
  }
  .yogaBallSec img {
    width: 100%;
  }
  .TeacherIconSec {
    position: absolute;
    top: -2rem;
  }
  .yogaBallSecHeight {
    height: 40px;
  }
  .displayBLOCK {
    display: block;
    text-align: start;
  }
}
