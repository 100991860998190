/*** Popup Style ***/
/* 按鈕右邊界 */
.loginButton {
  margin-right: 1em;
}
/* 給AutoPopup使用，提示標題樣式 */
.modalTitle {
  width: 100%;
  margin-left: 1.2em;
  text-align: center;
  font-size: 1.2em;
  color: #fa9600;
}
/* Popup 按鈕 - 登入/註冊文字 */
.loginText {
  color: #666;
}
.loginText:active {
  color: #eee;
}
/* Popup 整個內容區塊 */
.modal {
  background-color: white;
  border-radius: 3px;
}

/* Popup Header層 */
.modalHeader {
  /* padding: 8px; */
  border-width: 0;
  border: none !important;
  width: 100%;
  border-radius: 3px;
}
.modalClose {
  position: absolute;
  font-weight: 700;
  color: white;
  font-size: 20px;
  top: -25px;
  cursor: pointer;
}
.PopupBanner {
  border-radius: 3px;
  width: 100%;
  height: 100%;
}
.PopupBanner img {
  border-radius: 3px;
  width: 100%;
  height: 100%;
}
.modalBody {
  background-color: white;
}
/*** End Popup Style ***/

/* 取消自動填入 */
input {
  -webkit-text-security: "disc";
}
.inputinfiled {
  width: 100%;
  border: none;
  border-bottom: 1px;
  outline: none;
  border-bottom: 1px solid #585858;
}
.inputinfiled:disabled {
  background-color: #ddd;
}

.loginbuttosec {
  text-align: center;
}
.loginbutton {
  background-color: #1e8c96;
  border: none;
  width: 95%;
  color: white;
  padding: 0.3em;
  border-radius: 3px;
  font-size: 18px;
}
.loginbutton:disabled {
  background-color: #1e8c96aa;
}
.loginbutton:hover {
  box-shadow: 1px 1px 10px -1px #1e8c96;
}
.loginbutton:disabled:hover {
  box-shadow: none;
}
.form {
  width: 100%;
  /* background-color: #FFF096; */
  background-color: #ffffff;
  padding: 0.5rem;
  margin: auto;
  border-radius: 3px;
}
.KidProLoginTitleBox {
  display: flex;
  justify-content: center;
}
.KidProLoginMarkBox {
  display: inline;
  width: 200px;
}
.KidProLoginMark {
  width: 100%;
  height: 100%;
}
.KidProLoginTitle {
  font-size: 58px;
  color: #1e8c96;
  font-weight: bold;
}
.loginWayDirectSec {
  display: inline-block;
  /* width: 75%;     */
  flex: 5;
  text-align: end;
}
.loginWayDirect,
.loginWayDirect:active,
.loginWayDirect:visited {
  font-size: 1rem;
  font-weight: 600;
  text-align: end;
  color: #fa9600;
  cursor: pointer;
}
.loginWayDirect:hover {
  text-decoration: underline;
  color: #f26419;
}
.directSecCol {
  display: flex;
  width: 60%;
  margin: auto;
}
.directSec {
  width: 10rem;
  display: flex;
  margin: 0 auto;
  justify-content: space-around;
  text-align: center;
}
.padforgotSec a {
  color: #1e8c96;
}
.regSec a,
.regSec span {
  font-size: 16px;
  color: #fa9600;
  cursor: pointer;
  text-decoration: underline;
}
.regSec:hover a,
.regSec:hover span {
  color: #f26419;
  cursor: pointer;
  text-decoration: underline;
}
.mailLable {
  font-weight: bold;
  flex: 5;
}
.pdsLable {
  font-weight: bold;
}
.pdsLableEmail {
  font-weight: bold;
}
.divLineSec {
  width: 100%;
}
.divLine {
  width: 100%;
}
.socialLoginSec {
  width: 100%;
  display: flex;
  justify-content: center;
}
/* Google登入包含iframe之區塊 */
.SocialIconGoogle + div {
  /* margin-top: 8px; */
  margin-right: 0.5rem;
  width: 55px !important;
  height: 80px !important;
  position: relative !important;
  left: -20% !important;
  z-index: 10 !important;
  opacity: 0 !important;
}
/* 把Google登入iframe，覆蓋原圖片 */
.socialLoginSec iframe {
  width: 55px !important;
  height: 80px !important;
  cursor: pointer !important;
  /* position: relative!important;
    left: -100%!important;
    z-index: 10!important;
    opacity: 0!important; */
}
/* 社群登入區塊最前方空白區塊 */
.SocialIconNoimg {
  margin-right: 0.5rem;
  width: 55px !important;
  height: 80px !important;
  opacity: 0;
}
.SocialIconSec {
  display: flex;
}
.socialLoginTitle {
  margin: 1rem 0.5rem;
  color: #585858 !important;
  font-weight: bold;
}
.socialLoginTitle div {
  font-size: 16px;
}
.SocialIcon {
  margin-right: 0.5rem;
  cursor: pointer;
  /* 圖片z-index */
  z-index: 0;
  /* background-color: #FFF096; */
  background-color: #ffffff;
}
.countryCode {
  border: 1px solid #ced4da;
  box-shadow: none;
  /* border-radius: 0.375rem; */
  margin-right: 10px;
  border: none;
  border-bottom: 1px solid #585858 !important;
  outline: none;
}
.countryCode:focus {
  border: none;
  border-bottom: 1px solid #585858 !important;
  outline: none;
}
.countryCode:disabled {
  background-color: #ddd !important;
  color: #666 !important;
}
.PhoneSec {
  display: flex;
}
.RegxpErrorSec {
  width: 100%;
  text-align: center;
}

.RegxpError {
  color: #f26419;
}
.nextStepButton {
  background-color: #1e8c96;
  border: none;
  width: 95%;
  color: white;
  padding: 0.5em;
  border-radius: 3px;
}
.nextStepButton:hover {
  box-shadow: 1px 1px 10px -1px #1e8c96;
}
.nextStepButton:disabled {
  background-color: #1e8c9699;
  margin-top: 1rem;
}
.nextStepButton:disabled:hover {
  box-shadow: none;
}

/* 提示收取驗證信文字 */
.registerEmailTipSec {
  width: 100%;
  text-align: center;
}
.registerEmailTip {
  /* color: #1E8C96; */
  color: #555;
  font-weight: 600;
}
/* 電子郵件、電話與切換登入方式區塊 */
.LabelSwitchDiv {
  display: flex;
}
/****** 手機註冊按鈕 *****/
.form_buttonPhoneDiv {
  width: 100%;
  text-align: center;
}
.form_buttonPhone {
  width: 95%;
  margin-bottom: 1.5rem;
  font-size: 14px;
  background-color: #1e8c96;
  border: none;
  color: white;
  box-shadow: 1px 1px 1px rgb(150 145 145);
  padding: 0.5em;
  border-radius: 3px;
}
.form_reverifyButton {
  width: 95%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 14px;
  background-color: #1e8c96;
  border: none;
  color: white;
  box-shadow: 1px 1px 1px rgb(150 145 145);
  padding: 0.5em;
  border-radius: 3px;
}
.form_buttonPhoneSubmitSMS {
  margin-bottom: 0;
}
.form_buttonPhone:hover {
  box-shadow: 1px 1px 10px -1px #1e8c96;
}
.form_buttonPhone:disabled {
  background-color: #1e8c9699;
}
.form_reverifySec {
  display: block;
}
.form_smsSec {
  flex: 9;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}
.form_verifySMSDiv {
  width: 100%;
}
.form_inputPhoneSec {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}
.form_inputPhone:disabled {
  background-color: #ddd !important;
  color: #666 !important;
}
.form_inputSMS {
  width: 100%;
  border: none;
  border-bottom: 1px solid #585858;
  outline: none;
}
.form_buttonPhoneDiv {
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 540px) {
  .KidProLoginTitleBox {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .KidProLoginTitle {
    font-size: 58px;
    flex: 5;
    text-align: right;
    margin-top: 2px;
  }
  .KidProLoginMarkBox {
    display: inline-block;
    flex: 8;
  }
  .LabelSwitchDiv {
    justify-content: space-between;
  }
  .mailLable,
  .loginWayDirectSec {
    flex: initial;
  }
  .form_buttonPhoneDiv {
    width: 100%;
    text-align: center;
  }
  .form_buttonPhone {
    width: 95%;
    font-size: 14px;
    padding: 0.5em;
  }
  .form_smsSec {
    flex-wrap: wrap;
  }
  .form_buttonPhoneDiv {
    width: 100%;
    text-align: center;
  }
  .loginWayDirect {
    text-decoration: underline;
  }
}
