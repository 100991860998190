.memberTableCol{
    position: relative;
}
.titleH2{
    font-size: 28px!important;
    position: relative;
    margin: 0 auto!important;
    font-family: 'Roboto';
    font-style: normal;
    text-align: center;
    color: #FA9600!important;
    z-index: 10;
    width: fit-content;
}
.titleH3_4{
    font-size: 20px!important;
    position: relative;
    margin:auto!important;
    color: #1E8C96!important;
    text-align: center;
    z-index: 10;
    width: fit-content;
    border-radius: 3px;
    padding-top: 10px;
}
.titleH3_4:nth-last-child(1){
    padding-bottom: 10px;
}
.titleH4{
    font-size: 18px!important;
    position: relative;
    margin: 0 auto!important;
    color: #000!important;
    text-align: center;
    z-index: 10;
    width: fit-content;
}
.titleH5{
    font-size: 14px!important;
    font-weight: 600!important;
    color: #333;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 10%;
}
.titleH5 a{
    color: #333!important;
}
.titleH5 a:hover{
    text-decoration: underline;
}
.toShopBtnCol{
    margin: auto;
    margin: 38px auto 0;
    width: 70%;
    display: flex;
    justify-content: center;
}
.toShopBtn{
    width: 90%;
    background-color: #FA9600;
    border: none;
    color:white;
    box-shadow: 1px 1px 1px rgb(150, 145, 145);
    padding: .5rem;
    border-radius: 3px;
 }
 .toShopBtn:hover{
    box-shadow: 1px 1px 10px -1px #ffc107;
 }
    @media screen and (max-width: 1200px) {
        .titleH5{
            margin-right: 10px;
        }
    }
    @media screen and (max-width: 967px) {
        .titleH2{
            font-size: 28px!important;
        }
        .titleH4{
            font-size: 18px!important;
        }
        .toShopBtn{
            width: 100%;
            background-color: #FA9600;
            border: none;
            color:white;
            box-shadow: 1px 1px 1px rgb(150, 145, 145);
            padding: .5rem ;
            border-radius: 3px;
        }
    }
        @media screen and (max-width: 767px) {
            .titleH2{
                font-size: 24px!important;
            }
            .titleH3_4{
                font-size: 18px!important;
                /* padding: 0 16px 16px; */
            }
            .titleH4{
                font-size: 16px!important;
            }
        }
    