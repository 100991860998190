.lineFriendSec{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
}
.lineFriendQRCodeDiv{
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    padding: 16px 12px;
    background-color: #fff;
    text-align: center;
    /* word-break: keep-all; */
    word-wrap: break-word;
}
.lineFriendQRCodeTitle{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 12px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -.6px;
}
.lineFriendQRCodeDscrpt{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 5px;
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -.5px;
    font-weight: 300;
}
.lineFriendBox{
    display: flex;
    justify-content: center;
}
.lineFriendAnchor{
    width: 75%;
    display: flex; 
}
.lineFriendImg{
    width: 100%;
    display: flex;
}
.lineRegxpErrorSec{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8px;
}
.lineRegxpError{
    color: #f26419;
    font-style:oblique;
    width: 75%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.lineRegxpError a{
    text-decoration: none!important;
}
@media screen and (max-width: 967px) {
    .lineFriendQRCodeDiv{
        width: initial;
    }
}