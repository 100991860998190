.columnDiv{
    display: flex;
    flex-direction: column;
}
.columnDiv label{
    width: 100%;
    margin: 6px 0 6px 6px;
    font-weight: 600;
}
.columnDiv input{
    height: 1rem;
    padding: 16px!important;
}
.customerDataUpdatedIdcardDiv{
    display: flex;
    flex-direction: column;
}
.kidthumbnailNhiUrl{
    width: fit-content;
}