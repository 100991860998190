.tpfield {
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 16px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
}
.tappay-field-focus, .tpfield:focus, .tpfield:focus-within, .tpfield:focus-visible, .tpfield:target, .tpfield:visited, .tpfield:active  {
    border-color: #4096ff;
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    border-inline-end-width: 1px;
    outline: 0;
}
.tpfield:hover {
    border-color: #4096ff;
    border-inline-end-width: 1px;
}
.tpErrMsg{
    color: #F26419;
    display: flex;
    justify-content: center;
}
/* TapPay popup Modal */
.modal-dialog{
    /* max-width: 50%; */
    /* height: 100%; */
}
.MuiDataGrid-virtualScroller{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar{
    display: none; /* Chrome */
}
/* bootstrap button */
/* .btn-primary{
    background-color: #1e8c96!important;
    border-color: #1e8c96!important;
} */
@media screen and (max-width: 976px) {
    .tpfield {
        font-size: 12px;
    }
}