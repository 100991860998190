/* 按鈕右邊界 */
.loginButton {
  margin-right: 1em;
}

/* 給AutoPopup使用，提示標題樣式 */
.modalTitle {
  width: 100%;
  margin-left: 1.2em;
  text-align: center;
  font-size: 1.2em;
  color: #fa9600;
}
/* Popup 按鈕 - 登入/註冊文字 */
.loginText {
  color: #666;
}
.loginText:active {
  color: #eee;
}
/* Popup 整個內容區塊 */
.modal {
  background-color: white;
  border-radius: 3px;
}

/* Popup Header層 */
.modalHeader {
  /* padding: 8px; */
  border-width: 0;
  border: none !important;
  width: 100%;
  border-radius: 3px;
}
.modalClose {
  position: absolute;
  font-weight: 700;
  color: white;
  font-size: 28px;
  /* top: -42px; */
  /* font-size: 20px; */
  top: 4px;
  left: 4px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #ffffff66;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.PopupBanner {
  border-radius: 3px;
  width: 100%;
  height: 100%;
}
.PopupBanner img {
  border-radius: 3px;
  width: 100%;
  height: 100%;
}
.modalBody {
  background-color: white;
}
