input.elementor-field-textual {
    width: 33%;
}
.MuiBox-root {
    padding: 0!important;
    display: flex;
    align-items: center;
}
.MuiTypography-root {
    font-weight: 600 !important;
}
/* Labal置中 */
.MuiFormControlLabel-root{
    font-weight: 600 !important;
    display: flex!important;
}
/* Select、Input區塊 */
.MuiFormControl-root, .MuiFormGroup-root{
    width: 100%!important;
    margin: 0!important;
    display: flex;
}
#kidsRowDiv .MuiFormControl-root{
    margin: 0 1rem 0 0!important;
    border-color: #000;
    width: 100%!important;
}
/* 孩子生日區塊 */
#kidsRowDiv div:nth-child(2){
    /* margin: 0 1rem 0 0!important; */
    width: 100%!important;
}
/* #kidsRowDiv .MuiOutlinedInput-notchedOutline{
    border-color: rgba(0, 0, 0, 0.23);
} */
.MuiFormControl-root{
    width: 100%;
}
/* Input置中 */
.MuiTextField-root{
    display: flex;
    justify-content: center;
}
.MuiFormControlLabel-label{
    margin: 5px 0 0!important;
}
.MuiBox-root{
    background-color: #FFFFFF00;
    padding: 2rem 1rem 1rem 0rem;
    display: flex;
}
/* Date Picker */
.MuiInputBase-adornedEnd{
    width: 100%;
    border-radius: 5px;
    border-color: rgba(0, 0, 0, 0.23);
    padding: 4px;
    margin-bottom: 5px;
}
/* Date Picker Date */
.MuiInputBase-adornedEnd > input{
    width: 200%;
}
.MuiInputBase-formControl{
    width: 100%;
}
.MuiInputBase-root{
    background-color: rgba(0, 0, 0, 0.06);
}
.MuiInputBase-inputAdornedEnd{
    width: 250%;
}
/* 月曆圖示 */
.MuiInputAdornment-root{
    justify-content: flex-end;
}
#customer-name-div{
    width: 45%;
}
#isGaurdianSec {
    display: flex;
    flex-direction: column;
    padding: 20px!important;
    background-color: #EFEFEF;
    border-radius: 5px
}
#isKidsCarerSec {
    display: flex;
    flex-direction: column;
    padding: 20px!important;
    background-color: #EFEFEF;
    border-radius: 5px
}
#kid-name{
    flex: 8!important;
}
#kid-gender{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* flex: 2!important; */
}
#kid-birthday-label{
    flex: 2!important;
    width: 100%;
}
#kid-birthday{
    flex: 4!important;
    font-size: 16px;
    width: 100%;
}
/* label[for=kid-birthday]{
    font-size: 14px;
    margin: 0!important;
} */
#kid-idcard-label{
    flex: 2!important;
}
#kid-idcard{
    flex: 4!important;
}

    @media screen and (max-width:967px) {
        .MuiBox-root{
            background-color: #FFFFFF00;
            padding: 1rem 1rem 1rem 0rem;
            display: flex;
            align-items: center;
        }   
        /* Select、Input區塊 */
        .MuiFormControl-root, .MuiFormGroup-root{
            margin: 0px 1rem 0 0!important;
        } 
    }
        @media screen and (max-width:767px) {
            .MuiFormControl-root, .MuiFormGroup-root{
                width: 100%!important;
                margin: 0!important;
            }
            #kidsRowDiv .MuiFormControl-root{
                width: 100%!important;
            }
            #kidsRowDiv .MuiFormControl-root{
                margin: .75rem 0!important;
            }
            /* 孩子生日區塊 */
            #kidsRowDiv div:nth-child(2){
                margin: 0!important;
            }
            .MuiBox-root{
                flex-direction: column;
                padding: 0;
            }    
            .MuiBox-root > div{
                margin: 1rem 0!important;
                width: 100%;
            }
            .MuiBox-root > div:nth-child(2){
                width: 100%!important;
            }
            .MuiBox-root > div:nth-child(3){
                margin: 0!important;
            }
            #kid-birthday{
                padding: 13px;
            }
            #isGaurdianSec {
                padding: 12px!important;
            }
            #isKidsCarerSec {
                padding: 12px!important;
            }
        }