/* .ant-steps-item-content{
    display: inline;
    width: 0;
    outline: 1px solid;
} */
.ant-steps-item-icon {
    background-color: #1E8C96!important;
    border-radius: 50%!important;
    width: 42px!important;
    height: 42px!important;
    text-align: center!important;
}
.ant-steps-item-icon span{
    display: inline-block;
    width: 36px;
    height: 36px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 42px!important;
    color: white!important;
}
.ant-steps.ant-steps-vertical >.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
    top: 8px;
}
.ant-steps.ant-steps-vertical >.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail::after {
    width: 8px;
    height: 98%;
    background-color: hsla(180, 62%, 79%, 0.301);
}
/* 第四個步驟隱藏 */
.ant-steps-item-wait{
    display: none!important;
}