/* 隱藏Collapse icon */
/* .ant-collapse-expand-icon{
    display: none!important;
} */
.ant-checkbox {
    height: initial!important;
    margin: 0px 0 5px 0;
}
.ant-checkbox:focus-visible {
    outline: initial!important;
}
/* form padding  */
.ant-collapse .ant-collapse-content>.ant-collapse-content-box{
    padding: 0;
}
/*** 欄位寬度修正 ***/
.ant-form-item .ant-form-item-control-input{
    width: 100%;
}
/* Collapse Header */
.ant-collapse-header{
    padding: 0!important;
    align-items: center;
}
/* Ant Icon */
.anticon-close-circle, .anticon-right-circle{
    padding: 0;
}
/* Ant Loading Icon */
.ant-btn >.anticon+span, .ant-btn >span+.anticon[aria-label=loading]{
    position: relative;
    bottom: 4px;
}
.ant-btn >.anticon+span, .ant-btn >span+.anticon[aria-label=loading] svg{
    font-size: 20px;
}
/* form item label */
.ant-form-item .ant-form-item-label{
    padding: 0;
}
.ant-form-item .ant-form-item-label >label {
    height: initial;
}
/* form item select */
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: 34px;
    /* width: 12rem!important; */
}
/* form input */
.ant-form-item .ant-form-item-control-input{
    width: 100%;
}
/* form checkbox */
.ant-form input[type='checkbox']:focus{
    box-shadow: initial;
}
.ant-collapse .ant-collapse-content>.ant-collapse-content-box{
    padding: 0;
}
.ant-steps-item-description {
    margin: -24px auto 40px;
    padding: 0;
}
.ant-steps-vertical:nth-child(1) .ant-steps-item-description {
    margin: -24px auto 0;
}
/* ant Line Bind & Line Add Friend 區塊置中*/
:where(.css-dev-only-do-not-override-j0nf2s).ant-steps.ant-steps-vertical {
    display: flex;
    flex-direction: column;
    /* width: 54%;
    margin: 20px auto; */
}
#line-bind-section .ant-steps.ant-steps-vertical >.ant-steps-item{
    width: 370px!important;
}
:where(.css-dev-only-do-not-override-j0nf2s).ant-steps.ant-steps-vertical >.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
    position: absolute;
    top: 5px;
    inset-inline-start: 18px;
    width: 1px;
    height: 100%;
    padding: 38px 0 6px;
}
label.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item{
    display: flex!important;
    align-items: center;
}
/* Table Header 欄位 */
.ant-table-wrapper .ant-table-thead>tr>th, .ant-table-wrapper tfoot>tr>th, .ant-table-wrapper tfoot>tr>td {
    padding: 8px;
}
/* Table欄位置中 */
td.ant-table-cell{
    text-align: center;
    padding: 12px!important;
}
/* Table內包覆篩選項目的span */
.ant-table-filter-dropdown span.ant-dropdown-menu-title-content{
    display: flex;
}
/* Table內篩選項目的checkBox label */
.ant-table-filter-dropdown label.ant-checkbox-wrapper{
    display: flex!important;
}
/* Table內篩選項目的OK按鈕 */
.ant-dropdown .ant-btn{
    background-color: #1E8C96;
    color: #FFF;
}
.ant-dropdown .ant-btn:hover{
    background-color: #36a1ab!important;
    color: #FFF;
}
/* Table篩選漏斗 */
.ant-table-filter-trigger .anticon{
    color: #1E8C96;
}
.ant-table-filter-trigger:hover .anticon{
    color: #36a1ab;
}
.ant-table-wrapper .ant-table-filter-trigger.active .anticon{
    color: #138089;
}


@media screen and (max-width:760px) {
    /* form item select */
    /* .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
        height: 28px;
    } */
    .ant-steps-item-icon {
        width: 35px!important;
        height: 35px!important;
        margin-inline-end: 12px;
    }
    .ant-steps-item-icon span {
        display: inline-block;
        width: 20px;
        height: 20px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 35px!important;
        color: white!important;
    }
    .ant-row.ant-form-item-row{
        width: 100%!important;
    }
    .ant-steps.ant-steps-vertical >.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
        top: 2px;
    }
    :where(.css-dev-only-do-not-override-j0nf2s).ant-steps.ant-steps-vertical >.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
        position: absolute;
        top: 2px;
        inset-inline-start: 15px;
        width: 1px;
        height: 99%;
        padding: 36px 0 3px;
    }
    .ant-steps.ant-steps-vertical >.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail::after {
        width: 6px;
        height: 98%;
        background-color: hsla(180, 62%, 79%, 0.301);
    }
    /* Table欄位置中 */
    td.ant-table-cell{
        padding: 8px!important;
    }
}
