@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinnerSec {
  background-color: #00000006;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinnerSecDiv {
  background-color: #fff;
  padding: 20px;
  border-radius: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.spinnerImg {
  width: 120px;
  margin-bottom: 12px;
}
.container {
  position: relative;
  display: inline-block;
}
.text {
  white-space: nowrap;
  /* font-size: 28px;*/ /* 中文 */
  font-size: 20px; /* En */
  font-weight: bold;
  letter-spacing: 5px;
}
.textBackground {
  color: White;
  text-shadow: 0 0 6px #eee;
}
.textColor {
  position: absolute;
  left: 0;
  top: 0;
  color: #333;
  width: 50%;
  overflow: hidden;
  animation: widthChange 3s infinite;
}
@keyframes widthChange {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
